import React, { useState, useEffect, useRef } from "react";

const useInView = (options) => {
  const [isInView, setIsInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    //check if div has come to view
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  return [ref, isInView];
};

const ContentSkills = () => {
  const [activeSkillIndex, setActiveSkillIndex] = useState(-1);
  const skills = [
    { name: "C++", proficiency: 70 },
    { name: "Python", proficiency: 55 },
    { name: "Web Dev", proficiency: 90 },
    { name: "Excel VBA", proficiency: 80 },
    { name: "Excel", proficiency: 60 },
  ];

  // Use the custom hook to detect when the component is in view
  const [containerRef, isInView] = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (isInView && activeSkillIndex < skills.length - 1) {
      const timer = setTimeout(() => {
        setActiveSkillIndex((prev) => prev + 1);
      }, 800);

      return () => clearTimeout(timer);
    }
  }, [isInView, activeSkillIndex]);

  return (
    <div id="contentSkills" ref={containerRef} className="skills-container bg-gray-100 p-8">
      <h2 className="text-4xl font-bold text-center mb-8">Skills</h2>
      <p className="text-center text-xl mb-6">Proficiency Breakdown</p>

      <div id="skillsBar" className="skills-list space-y-6 mx-auto">
        {skills.map((skill, index) => (
          <div key={index} className="skill-item">
            <div className="flex justify-between mb-2">
              <span className="font-bold">{skill.name}</span>
            </div>
            <div className="w-full bg-gray-300 h-4 rounded">
              <div
                className={`bg-blue-500 h-4 rounded transition-all duration-1000 ease-in-out`}
                style={{
                  width: activeSkillIndex >= index ? `${skill.proficiency}%` : "0%",
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentSkills;
