import React from "react";

function Footer() {
  return (
    <footer className="footer bg-neutral text-neutral-content items-center p-4 flex flex-col md:flex-row md:justify-between gap-y-0">
      <aside className="grid-flow-col items-center mb-2 md:mb-0">
        <p>Copyright © {new Date().getFullYear()} - All rights reserved</p>
      </aside>
      <nav className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
        <a id="socialLinkedIn" href="https://www.linkedin.com/in/ricky-chen-2b24b562" target="_blank">
          <span className="[&>svg]:h-5 [&>svg]:w-5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 448 512">
              <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
            </svg>
          </span>
        </a>
        <a id="socialGmail" href="mailto:rickychen10@gmail.com?Subject=Interview">
          <span className="[&>svg]:h-5 [&>svg]:w-5 [&>svg]:fill-[#ea4335]">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
              <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6c98 .2 .2-.4 .2-.4z" />
            </svg>
          </span>
        </a>
      </nav>
    </footer>
  );
}

export default Footer;
