import "../css/index.css";
import Parallax from "../components/Parallax";
import heroImage from "../assets/home/home-hero.jpg";
import ContentExperience from "../components/ContentExperience";
import ContentEducation from "../components/ContentEducation";
import ContentSkills from "../components/ContentSkills";
import ContentProjects from "../components/ContentProjects";
import ScrollTopBtn from "../components/ScrollTopBtn";

function Home() {
  return (
    <div className="relative ">
      <Parallax imageUrl={heroImage} title="" />
      <ContentExperience className="mt-[200px] contentProfile" />
      <div className="contentDiver divider w-100"></div>
      <ContentEducation className="mt-[200px]" />
      <ContentSkills className="mt-[200px] contentProfile" />
      <ContentProjects className="mt-[200px]" />
      <ScrollTopBtn />
    </div>
  );
}

export default Home;
