import "../css/index.css";
import ContentIntro from "./ContentIntro";

const Parallax = ({ imageUrl }) => {
  return (
    <div
      id="parallax"
      style={{ backgroundImage: `url(${imageUrl})` }}
      className="h-3/4 bg-fixed bg-center bg-cover sm:bg-cover md:bg-cover flex items-center justify-center relative"
    >
      <ContentIntro />
    </div>
  );
};

export default Parallax;
