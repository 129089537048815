import { useEffect, useState } from "react";
import "../css/index.css";
import { HashLink } from "react-router-hash-link";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    console.log("isOpen state changed:", isOpen);
  }, [isOpen]);

  const openCloseMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div id="topNav" className="navbar bg-base-100 bg-neutral text-neutral-content shadow-xl rounded-box w-4/5 mx-auto">
      <a href="/" className="btn btn-ghost normal-case text-xl">
        Ricky Chen
      </a>
      <div id="hamburger" className="navbar-end">
        <div onClick={openCloseMenu} className="dropdown lg:hidden">
          <div tabIndex="0" role="button" className="btn btn-ghost btn-circle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" />
            </svg>
          </div>
          {isOpen && (
            <ul
              id="hamburgerDropdown"
              tabIndex="0"
              className={`fixed inset-0 z-[9999] flex flex-col items-center justify-center bg-base-100 text-xl space-y-6`}
            >
              <li>
                <HashLink smooth={true.toString()} to="/#contentExperience">
                  Experience
                </HashLink>
              </li>
              <li>
                <HashLink smooth={true.toString()} to="/#contentEducation">
                  Education
                </HashLink>
              </li>
              <li>
                <HashLink smooth={true.toString()} to="/#contentSkills">
                  Skills
                </HashLink>
              </li>
              <li>
                <HashLink smooth={true.toString()} to="/#contentProjects">
                  Projects
                </HashLink>
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="navbar-end hidden lg:flex w-full">
        <ul className="menu menu-horizontal px-1 ">
          <li>
            <HashLink smooth={true.toString()} to="/#contentExperience">
              Experience
            </HashLink>
          </li>
          <li>
            <HashLink smooth={true.toString()} to="/#contentEducation">
              Education
            </HashLink>
          </li>
          <li>
            <HashLink smooth={true.toString()} to="/#contentSkills">
              Skills
            </HashLink>
          </li>
          <li>
            <HashLink smooth={true.toString()} to="/#contentProjects">
              Projects
            </HashLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
