const DownloadResume = () => {
  return (
    <div className="flex justify-center items-center mt-4">
      <a
        href="https://drive.google.com/file/d/1te9oY9usmsRbcBPdiCMI5WpvcdWR9nNc/view?usp=sharing"
        target="_blank"
        download
        className="btn btn-primary btn-lg flex items-center gap-2 px-6 py-3 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={2}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 16v4a2 2 0 002 2h12a2 2 0 002-2v-4m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
        Resume
      </a>
    </div>
  );
};

export default DownloadResume;
