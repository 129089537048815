import "../css/index.css";
import { useState, useEffect } from "react";

const ScrollTopBtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    document.getElementById("topNav").scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const targetElement = document.getElementById("parallax");
    const handleIntersection = (entries) => {
      const entry = entries[0];
      if (!entry.isIntersecting) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.1,
    });

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  return (
    <button
      id="scrollToTopBtn"
      onClick={scrollToTop}
      className={`fixed bg-blue-500 text-white shadow-lg 
        transition-transform transform hover:scale-110 
        z-50 w-20 h-10 rounded-t-full md:hidden md:bottom-0 md:right-1/2 md:translate-x-1/2 ${
          isVisible ? "block" : "hidden"
        }`}
    >
      ↑
    </button>
  );
};

export default ScrollTopBtn;
