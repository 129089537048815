import React from "react";

function ProjectCard({ title, description, photos, onNext, onPrevious, isFirst, isLast }) {
  return (
    <div className="flex flex-col items-center justify-between bg-base-200 p-8 relative">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center mb-8 max-w-4xl">
        <h1 className="text-3xl font-bold text-center md:text-right">{title}</h1>
        <p className="text-lg text-white-700 text-center md:text-left">{description}</p>
      </div>
      <div className="w-full max-w-4xl min-h-screen">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          {photos.map((photo, index) => (
            <div key={index} className="w-full h-[400px] overflow-hidden rounded-lg shadow-lg">
              <img src={photo} alt={`Project ${index + 1}`} className="w-full h-full object-cover" />
            </div>
          ))}
        </div>
        <div className="flex justify-between mt-4">
          {!isFirst && (
            <button onClick={onPrevious} className="btn btn-secondary">
              Previous Project
            </button>
          )}
          {!isLast && (
            <button onClick={onNext} className="btn btn-primary">
              Next Project
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
