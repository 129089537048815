import "../css/index.css";
import Project1Image from "../assets/projects/project-1-show.png";
import Project2Image from "../assets/projects/project-2-home.png";
import Project3Image from "../assets/projects/project-3.png";
import { useNavigate } from "react-router-dom";

const ContentProjects = () => {
  const projects = [
    {
      id: 1,
      coverPhoto: Project1Image,
      title: "Project 1",
    },
    {
      id: 2,
      coverPhoto: Project2Image,
      title: "Project 2",
    },
    {
      id: 3,
      coverPhoto: Project3Image,
      title: "Project 3",
    },
  ];
  const navigate = useNavigate();

  function handleProjectClick(id) {
    //navigate to specific project path
    navigate(`/projects/${id}`);
  }

  return (
    <div id="contentProjects" className="min-h-[35vh] bg-base-200">
      <div className="max-w-6xl pt-8 text-center mx-auto">
        <h2 className="text-4xl font-bold">Projects</h2>
        <div className="divider mx-auto"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-8 max-w-6xl text-center mx-auto">
        {projects.map((project) => (
          <div
            key={project.id}
            className="card bg-base-100 image-full shadow-xl projectCardImg"
            onClick={() => handleProjectClick(project.id)}
          >
            <figure>
              <img className="w-full h-auto object-cover" src={project.coverPhoto} alt={project.title} />
            </figure>
            <div className="card-body flex items-center justify-center">
              <h2 className="card-title text-center">{project.title}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentProjects;
