import "../css/index.css";
import React, { useState } from "react";
import ProjectCard from "../components/ProjectCard";
import project2HomeImage from "../assets/projects/project-2-home.png";
import project2WorkoutImage from "../assets/projects/project-2-workout.jpg";
import project2Workout2Image from "../assets/projects/project-2-workout2.jpg";
import project2Workout3Image from "../assets/projects/project-2-workout3.jpg";
import project1ShowImage from "../assets/projects/project-1-show.png";
import project1HomeImage from "../assets/projects/project-1-home.png";
import project1Show2Image from "../assets/projects/project-1-show2.png";
import project1ProfileImage from "../assets/projects/project-1-profile.png";
import project3Image from "../assets/projects/project-3.png";
import project3Stock1Image from "../assets/projects/project-3-stock1.jpg";
import project3Stock2Image from "../assets/projects/project-3-stock2.jpg";
import project3Stock3Image from "../assets/projects/project-3-stock3.jpg";
import { useParams } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";

function Projects() {
  const projects = [
    {
      id: 1,
      title: "Project 1",
      description:
        "This passion project aggregates top free TV rating scores from platforms like TV Maze, IMDb, and TMDB for TV shows and movies. I created it to streamline the process of finding comprehensive ratings in one place, inspired by my frustration with having to visit multiple sites to gather information about a show or movie.",
      photos: [project1ShowImage, project1HomeImage, project1Show2Image, project1ProfileImage],
    },
    {
      id: 2,
      title: "Project 2",
      description: "This was just to recreate My Fitness Pal for fun. Nothing too innovative.",
      photos: [project2HomeImage, project2WorkoutImage, project2Workout2Image, project2Workout3Image],
    },
    {
      id: 3,
      title: "Project 3",
      description:
        "This VBA macro automated the process of unzipping a monthly report, performing an hour's worth of pivoting and filtering tasks, and then re-zipping the file and emailing it back to me, streamlining what was once a time-consuming workflow.",
      photos: [project3Image, project3Stock1Image, project3Stock2Image, project3Stock3Image],
    },
  ];

  const { id } = useParams();
  const initialIndex = projects.findIndex((project) => project.id === parseInt(id));
  const [currentProjectIndex, setCurrentProjectIndex] = useState(initialIndex >= 0 ? initialIndex : 0);

  const handleNext = () => {
    document.getElementById("topNav").scrollIntoView({
      behavior: "auto",
    });
    setCurrentProjectIndex((prevIndex) => (prevIndex === projects.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevious = () => {
    document.getElementById("topNav").scrollIntoView({
      behavior: "auto",
    });
    setCurrentProjectIndex((prevIndex) => (prevIndex === 0 ? projects.length - 1 : prevIndex - 1));
  };

  const currentProject = projects[currentProjectIndex];
  return (
    <div>
      <ScrollToTop />
      <ProjectCard
        title={currentProject.title}
        description={currentProject.description}
        photos={currentProject.photos}
        onNext={handleNext}
        onPrevious={handlePrevious}
      />
    </div>
  );
}

export default Projects;
