import React from "react";
import "../css/index.css";

function NotFound() {
  return (
    <div className="not-found min-h-[86vh]">
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
}

export default NotFound;
