import "../css/index.css";
import Timeline from "../components/Timeline";

const ContentEducation = () => {
  const timelineData = [
    {
      year: "2010-2015",
      info: "Bachelors",
      establishment: "UC Riverside",
      description: "BS in Computer Science",
    },
    {
      year: "2006 - 2010",
      info: "High School Diploma",
      establishment: "Galileo High School",
      description: "Home of the mighty Lions",
    },
  ];

  return (
    <div id="contentEducation" className="hero min-h-[45vh] bg-base-200">
      <div className="hero-content text-center">
        <div className="w-full max-w-lg sm:max-w-xl md:max-w-6xl">
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold  mb-2 sm:mb-1">Education</h2>
          <div className="divider"></div>
          <Timeline id="timelineEducation" data={timelineData} />
        </div>
      </div>
    </div>
  );
};

export default ContentEducation;
