import React from "react";
import Timeline from "../components/Timeline";

const ContentExperience = () => {
  const timelineData = [
    {
      year: "2019 - Present",
      info: "Sr. Technical Specialist",
      establishment: "Flashtalking | Mediaocean",
      description:
        "Manage day-to-day support tickets to address and resolve issues related to Flashtalking products, including campaign performance discrepancy analysis and other operational challenges.",
    },
    {
      year: "2016 - 2019",
      info: "Manager, Technical Ad Ops",
      establishment: "Hearst Autos",
      description:
        "Managed day-to-day support tickets to resolve and prevent issues, including broken HTML5 ads, custom product malfunctions, and publisher-related challenges.",
    },
    {
      year: "2014 - 2014",
      info: "Internship",
      establishment: "CKE Restaurants Inc.",
      description:
        "Collaborated with IT Security, Change Management Analysts, and the Director of IT Security, Risk, and Compliance to conduct vulnerability network scans and manage daily operational tasks.",
    },
  ];

  return (
    <div id="contentExperience" className="hero min-h-[45vh] bg-base-200">
      <div className="hero-content text-center">
        <div className="w-full max-w-lg sm:max-w-xl md:max-w-6xl">
          {/* Reduced margin for mobile */}
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-2 sm:mb-1">Experience</h2>
          {/* Divider with reduced margin */}
          <div className="divider mb-2 sm:mb-4"></div>
          {/* Timeline */}
          <Timeline data={timelineData} />
        </div>
      </div>
    </div>
  );
};

export default ContentExperience;
