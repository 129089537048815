import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.getElementById("topNav").scrollIntoView({
      behavior: "auto",
    });
  }, [pathname]);

  return null;
}
