import React, { useEffect } from "react";
import "../css/index.css";

const Timeline = ({ data }) => {
  useEffect(() => {
    const dots = document.querySelectorAll(".timeline-dot");
    const timelineContentLeft = document.querySelectorAll(".timeline-content-left");
    const timelineContentRight = document.querySelectorAll(".timeline-content-right");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains("timeline-dot")) {
              entry.target.classList.add("animate-dot");
            } else if (entry.target.classList.contains("timeline-content-left")) {
              entry.target.classList.add("animate-content-left");
            } else if (entry.target.classList.contains("timeline-content-right")) {
              entry.target.classList.add("animate-content-right");
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    dots.forEach((dot) => observer.observe(dot));
    timelineContentLeft.forEach((content) => observer.observe(content));
    timelineContentRight.forEach((content) => observer.observe(content));

    return () => observer.disconnect();
  }, []);

  return (
    <div className="timeline relative">
      <div className="timeline-line"></div>
      {data.map((item, index) => (
        <div key={index} className="timeline-item flex items-center gap-4 relative">
          <div className="timeline-content-left text-right">
            <p className="yearText">{item.year}</p>
            <p className="infoText">{item.info}</p>
          </div>
          <div className="timeline-dot"></div>
          <div className="timeline-content-right">
            <h3 className="establishmentName">{item.establishment}</h3>
            <p className="establishmentDescription text-justify">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
