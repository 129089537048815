import DownloadResume from "./DownloadResume";
import "../css/index.css";
import ProfilePhoto from "../assets/home/home-potrait.jpg";

const ContentIntro = () => {
  return (
    <div className="absolute bottom-0 flex flex-col md:flex-row justify-center items-center pb-8 content-wrapper">
      <div id="profilePhoto" className="photo flex justify-center items-center">
        <img
          src={ProfilePhoto}
          alt="Photo"
          className="w-40 h-40 sm:w-48 sm:h-48 md:w-60 md:h-60 object-cover rounded-full"
          id="profileImg"
        />
      </div>

      <div id="description" className="description flex items-center">
        <div className="w-full max-w-lg sm:max-w-xl md:max-w-4xl">
          <p id="introName" className="font-bold mb-4 text-nowrap text-[#292524]">
            Hello! I'm <span className="text-[#166534]">Ricky Chen</span>
          </p>
          <p id="introDescription" className="text-base leading-relaxed text-[#292524] font-bold text-justify">
            A QA professional with over 9 years of experience in Ad Tech, specializing in ensuring ad delivery and
            performance. With a Bachelor's in Computer Science, I bring a strong foundation in technology and a passion
            for problem-solving. My expertise lies in front-end development, though I'm equally adept at full-stack
            projects when needed. Outside of work, I enjoy exploring side projects that sharpen my technical skills and
            fuel my creativity.
          </p>
          <DownloadResume />
        </div>
      </div>
    </div>
  );
};

export default ContentIntro;
